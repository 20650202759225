import { ChangeDetectorRef, Component, inject, Input } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
  standalone: true,
  imports: [TranslateModule],
})
export class LoaderComponent {
  @Input() loadingText: string;
  @Input() delayMs = 500;
  public isVisible = false;
  private readonly cd = inject(ChangeDetectorRef);
  private readonly timeout: any;

  ngOnInit(): void {
    setTimeout(() => {
      this.isVisible = true;
      this.cd.detectChanges();
    }, this.delayMs);
  }

  public ngOnDestroy(): void {
    clearTimeout(this.timeout);
  }
}
